/* Existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

.header {
  background: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.nav {
  background: #444;
  color: #fff;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.nav a {
  color: #fff;
  margin: 5px 15px;
  text-decoration: none;
}

.section {
  padding: 20px;
  margin: 10px;
}

.footer {
  text-align: center;
  padding: 10px;
  background: #333;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* New responsive styles */
@media screen and (max-width: 768px) {
  .header {
    padding: 5px 0;
  }

  .header h1 {
    font-size: 24px;
  }

  .nav {
    flex-direction: column;
  }

  .nav a {
    margin: 5px 0;
    padding: 5px 0;
    width: 100%;
    border-bottom: 1px solid #555;
  }

  .nav a:last-child {
    border-bottom: none;
  }

  .section {
    padding: 10px;
    margin: 5px;
  }

  .section h2 {
    font-size: 20px;
  }

  .section h3 {
    font-size: 18px;
  }

  .section p, .section li {
    font-size: 14px;
  }

  .footer {
    position: static;
    padding: 5px;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 20px;
  }

  .section h2 {
    font-size: 18px;
  }

  .section h3 {
    font-size: 16px;
  }

  .section p, .section li {
    font-size: 12px;
  }
}

/* Ensure content isn't hidden behind footer on small screens */
body {
  padding-bottom: 60px; /* Adjust based on your footer height */
}

@media screen and (max-width: 768px) {
  body {
    padding-bottom: 0;
  }
}